import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { ConvaiChat } from '../convaiChat';
// import { toggleChat } from '../../../App'; // Import the toggleChat function

export const ChatWindow = ({ client, toggleChat }) => {
;
  

  const [isHovered, setIsHovered] = useState(false);
  const [history, setHistory] = useState(1);
  const [session, setSession] = useState("-1");
  const [messages, setMessages] = useState([]);
  const [errorResponse, setErrorResponse] = useState(false);
  const timer = useRef(null);
  const errorMessage = "Error in retrieving response. Please reset session.";

  let baseURL = (typeof(window['pluginData']) !== 'undefined') ? window['pluginData']['pluginBuildUrl'] :'';
  

  const [audioMuted, setAudioMuted] = useState(true); // Add this line


  useEffect(() => {
    console.log(client);
    
  }, [])

  //check if client is ready so we can set audio state to default
  useEffect(() => {
    if (client.convaiClient.current) {
      // Client is ready, perform any necessary operations
      // console.log('set audio mute/unmute to default');
      if (audioMuted && client.convaiClient.current.getAudioVolume()===1){
        client.convaiClient.current.toggleAudioVolume();
      }
    }
  }, [client]);

  const userInput = useCallback((text) => {
    client?.setUserText(text);
  }, [client]);

  const handleMicPress = ((e) => {
    console.log("handleMicPress");
    
    client?.handleMicPress(e);
    
  })
  const handleMicClick = ((e) => {
    console.log("handleMicClick");
    client?.handleMicClick(e);
    
  })
  const handleMicRelease = ((e) => {
    console.log("handleMicRelease");
    client?.handleMicRelease(e);
    // client?.convaiClient.handleMicRelease(e);
  })
  const handleMicTouchPress = ((e) => {
    e.preventDefault();
    console.log("handleTouchPress");
    window.alert("MicTouchPress");
    
    client?.handleMicPress(e);
    
  })
  const handleMicTouchRelease = ((e) => {
    console.log("handleMicTouchRelease");
    setTimeout(() => {
    client?.handleMicRelease(e);}, 100);
    // client?.convaiClient.handleMicRelease(e);
  })
  const muteAudio = ((e) => {
    console.log("muteAudio");
    setAudioMuted(true);
    if ( client?.convaiClient.current.getAudioVolume()===1){
      client?.convaiClient.current.toggleAudioVolume();
    }
    
  })
  const unMuteAudio = ((e) => {
    console.log("unmuteAudio");
    setAudioMuted(false);
    if ( client?.convaiClient.current.getAudioVolume()===0){
      client?.convaiClient.current.toggleAudioVolume();
    }
    
  })
  // const handleToggleChat = () => {
  //    // Call the toggleChat function
  //    to
  // };

  // This useCallback hook is used to reset the chat history and clear the messages.
  // It also resets the session ID and clears the user and NPC text.
  // It also calls the resetSession method of the ConvAI client if it exists.
  const ResetHistory = useCallback(() => {
    const storedData = localStorage.getItem("messages");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      parsedData[client?.characterId] = {
        sessionID: -1,
        message: [""],
      };
      localStorage.setItem("messages", JSON.stringify(parsedData));
    }
    if (client?.convaiClient?.current) {
      client.convaiClient.current.resetSession();
    }
    setSession("-1");
    setMessages([]);
    client?.setUserText("");
    client?.setNpcText("");
  }, [client]);

  // This useEffect hook is used to load and set the messages from local storage whenever the characterId changes.
  // It also handles the case when the message is stored as an empty string in local storage.
  useEffect(() => {
    const storedData = localStorage.getItem("messages");
    if (client?.characterId && storedData) {
      
      const parsedData = JSON.parse(storedData);
      if (parsedData[client.characterId]) {
        setSession(parsedData[client.characterId].sessionID || "-1");
        //Error checking if the message is set to an empty string then return empty array, otherwise it will return a JSON.parse error
        if (parsedData[client.characterId].message !=""){
          setMessages(JSON.parse(parsedData[client.characterId]["message"]));
        }
        else {
          setMessages([]);
        }
      } else {
        setSession("-1");
        setMessages([]);
      }
    } else {
      setSession("-1");
      setMessages([]);
    }
  }, [client?.characterId]);

  // This useEffect hook is used to save the messages to local storage whenever the characterId and messages change.
  // It also handles the case when the audio is muted and ensures that the ConvAI client's audio is muted accordingly.
  useEffect(() => {
    if (client?.characterId && messages.length) {
      // Convert messages to JSON string
      const messagesJSON = JSON.stringify(messages);
      // Get stored data from local storage
      const storedData = localStorage.getItem("messages");
      // Parse stored data if it exists, otherwise create an empty object
      const parsedData = storedData ? JSON.parse(storedData) : {};
      // Update the stored data with the current characterId and messages
      parsedData[client.characterId] = {
        sessionID: session,
        message: messagesJSON,
      };
      // Save the updated data back to local storage
      localStorage.setItem("messages", JSON.stringify(parsedData));
    }
  }, [client?.characterId, messages, session, audioMuted]);

  // This useEffect hook is used to handle the user's text input and the end of response.
  // When the user's text input is not empty and the user has ended their response,
  // it adds a new message object to the messages array with the sender as "user" and the content as the user's text.
  // It also clears the user's text input, sets the user's end of response status to false,
  // and starts a timer to set the error response status to true after 7000 milliseconds.
  useEffect(() => {
    if (client?.userText !== "" && client?.userEndOfResponse) {
      setMessages((prev) => [...prev, { sender: "user", content: client.userText }]);
      //console.log("userTExt:"+client.userText);
      client?.setUserText("");
      client.setUserEndOfResponse(false);
      timer.current = setTimeout(() => setErrorResponse(true), 2000);
    }
  }, [client?.userEndOfResponse, client?.userText]);
  

  // This useEffect hook is used to handle the NPC's text response.
  // When the NPC's text response is not empty and the text response is complete
  // it adds a new message object to the messages array with the sender as "npc" and the content as the NPC's text.
  useEffect(() => {
    if (errorResponse && !client?.npcText) {
      setMessages((prev) => [...prev, { sender: "npc", content: errorMessage }]);
      client?.setNpcText("");
      setErrorResponse(false);
    } else if (client?.npcText !== "") {
      setErrorResponse(false);
      clearTimeout(timer.current);
  
      if (!client.isTalking) {
        client?.setNpcText("");
      }
    }
  }, [client?.isTalking, errorResponse, client?.npcText]);



  useEffect(() =>{
    console.log("errorResponse")
  }, [errorResponse])

  // useEffect(() =>{
  //   console.log("client?.npcText:"+client.npcText)
  // }, [client?.npcText])

  //store the npc content when the userEndOfResponse is reached,
  //then after it is reset, clear the npcText
  useEffect(() =>{
    console.log("client?.userEndOfResponse:"+client.userEndOfResponse)
    if (client?.userEndOfResponse){
      setMessages((prev) => [...prev, { sender: "npc", content: client.npcText }]);
    }
    else {
      client?.setNpcText("");
    }
  }, [client?.userEndOfResponse])
  

  const memoizedMessages = useMemo(() => messages, [messages]);
  return (
    <>
      <section className="ConverSIM-chat-container-button">
        <div style={{ display: "flex" }}>
          <div className="ConverSIM-topBlock"
            
            
          >
            
              <img className="ConverSIM-resetSessionImage" loading="lazy" src={baseURL+'reset.png'} alt="reset chat"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={ResetHistory}
               />
              
              <img className="ConverSIM-closeSessionImage" loading="lazy" src={baseURL+'CloseIcon.png'} alt="close chat"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={toggleChat} />
          
            
          
            <div className="ConverSIM-resetSessionText" >
                Reset Session
              </div>
              <div className="ConverSIM-closeSessionText" >
                Hide
              </div>
            </div>
        </div>
        <ConvaiChat
          userText={client?.userText}
          npcText={client?.npcText}
          messages={memoizedMessages}
          userInput={userInput}
          chatHistory={"Show"}
          keyPressed={client?.keyPressed}
          setEnter={client?.setEnter}
          npcName={client?.npcName}
          userName={client?.userName}
          handleMicClick={handleMicClick}
          handleMicPress={handleMicPress}
          handleMicRelease={handleMicRelease}
          handleMicTouchPress={handleMicTouchPress}
          handleMicTouchRelease={handleMicTouchRelease}
          audioMuted={audioMuted}
          unMuteAudio={unMuteAudio}
          muteAudio={muteAudio}
        />
      </section>
    </>
  );
};
