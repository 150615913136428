import React, { useState, useRef} from 'react';

// Lazy load the FloatChat component
import { FloatChat } from './components/convaiChat/floatChat';

function App() {
  const [isOpen, setIsOpen] = useState(true);
  const chatRef = useRef(null);

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const toggleChatHandler = () => {
    toggleChat();
  };

  let baseURL = (typeof(window['pluginData']) !== 'undefined') ? window['pluginData']['pluginBuildUrl'] :'';

  return (
    <div className="ConverSIM-App" >
      <div>
        {/* <div className="ConverSIM-chat-container-outer" ref={chatRef}> */}
          {
            isOpen && (
              
                <FloatChat toggleChat={toggleChatHandler} />
              
            )
          }
        {/* </div> */}
        <button
          onClick={toggleChat}
          className="ConverSIM-chat-button">
            {/* Image being shown as clickable button */}
          <img src={baseURL+"ActivationLogo-Beta.png"} alt='csiLogo' id="ConverSIM-ActivationLogo"/>
        </button>
      </div>
    </div>
  );
}

export default App;