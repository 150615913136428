'use client'

import React, { useState, useRef } from 'react';
import "./floatChat.css"
// import Image from 'next/image'
import { ChatWindow } from './chatWindow';
import { useConvaiClient } from '../../useConvaiClient';

export const FloatChat = (props) => {

  var {
    toggleChat
  } = props;

  // const [isOpen, setIsOpen] = useState(false);
  const chatRef = useRef(null);
  
  // const toggleChat =() =>{
    
  //   setIsOpen(!isOpen);

  // }
  const { client } = useConvaiClient("c603e6f2-7b5e-11ef-82f1-42010a7be011", "fff19874ac25fe7a5b4830f3d826082e"); //FEE - I,Pencil / 


  return (
    <div>
      
      
      <div className="ConverSIM-chat-container" ref={chatRef}>
        {
          client && <ChatWindow client={client} toggleChat={toggleChat} />
        }
        
      </div>
      </div>
  );
};
export default FloatChat;
