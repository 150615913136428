import React, { useEffect, useRef, useState } from 'react';
import DOMPurify from 'dompurify';

let baseURL = (typeof(window['pluginData']) !== 'undefined') ? window['pluginData']['pluginBuildUrl'] :'';

export const ConvaiChat = (props) => {
  var {
    userText,
    npcText,
    messages,
    userInput,
    keyPressed,
    setKeyPressed,
    setEnter,
    npcName,
    userName,
    handleMicClick,
    handleMicPress,
    handleMicRelease,
    handleMicTouchPress,
    handleMicTouchRelease,
    muteAudio,
    unMuteAudio,
    audioMuted,
  } = props;
  const [value, setValue] = useState("");
  const containerRef = useRef(null);
  const [feedbacks, setFeedbacks] = useState(Array(messages?.length).fill(0));

  //To keep the scroll bar fixed to the bottom
  useEffect(() => {
    const container = containerRef.current;
    container.scrollTop = container.scrollHeight;
    // document.getElementById("ConverSIM-MicIcon").oncontextmenu = function() {
    //   document.log("context Menu");
    // }
    document.getElementById("ConverSIM-MicIcon").oncontextmenu = function(event) {
      console.log("context Menu");
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
    }
  });

  // document.getElementById("ConverSIM-MicIcon").oncontextmenu = function(event) {
  //   console.log("context Menu");
  //   event.preventDefault();
  //   event.stopPropagation();
  //   event.stopImmediatePropagation();
  // }

  // function mouseDownHandler(){
  //   console.log("mouseDownHandler");
  //   handleMicPress;
  // }

  useEffect(() => {
    console.log(messages)
  }, [messages])

  //Handles the input from the textBox
  const handleChange = (e) => {
    e.stopPropagation();
    setValue(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    userInput(value);
    setEnter(1);
    setValue("");
  };

  const mouseDown=(e) => {
    // e.stopPropagation();
    // e.preventDefault();
    console.log("mouse down");
  }

  const mouseUp=(e)=>{
    console.log("mouse Up");
  }

  const pointerDown=(e) => {
    // e.stopPropagation();
    // e.preventDefault();
    console.log("pointer down");
  }

  const pointerUp=(e)=>{
    console.log("pointer Up");
  }

  const touchStart=(e) => {
    console.log("touchStart");
    // e.preventDefault();
  }
  const touchEnd=(e)=>{
    console.log("touchEnd");
    // e.preventDefault();
  }
  const touchMove=(e)=>{
    alert("touchMove!");
    e.preventDefault();
  }
  const touchCancel = (e)=>{
    e.preventDefault();
    alert("touchCancel!");
  }
  const touchCancelCapture=(e)=>{
    alert("touchCancelCapture!");
    e.preventDefault();
  }
  const stopContextMenu = (e)=>{
    console.log("stopContextMenu");
    e.preventDefault();
    e.nativeEvent.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    return false;
  }

  const preventImageContextMenu = (event) => {
    alert("preventCOntextMenu");
    event.preventDefault();
  };

  const processHTMLLinks = (text) =>{
    const linkRegex = /https?:\/\/[^\s]+/g;
        const linkMatches = text.match(linkRegex);
        const npcMessage = text;
        let updatedMessage = npcMessage;
  
        if (linkMatches) {
          linkMatches.forEach((link) => {
            const linkText = link.replace(linkRegex, `<a href="${link}" target="_blank">${link}</a>`);
            updatedMessage = updatedMessage.replace(link, linkText);
          });
        }
        return updatedMessage;
  }

  return (
    <section
      className="ConverSIM-container"
      style={{
        background: "rgba(0, 0, 0, 0.77)",
      }}
    >
      <div
        className="ConverSIM-container-chat1"
        ref={containerRef}
        
      >
        {messages.map((message, idx) => {
          const nextMessage = messages[idx + 1];
          const isNextMessageUser =
            !nextMessage || nextMessage.sender === "user";
          const isNextMessageNpc =
            !nextMessage || nextMessage.sender === "npc";

          // const messageStyle = {
          //   color: "#FFFFFF",
          //   paddingLeft: "15px",
          //   marginRight: "20px",
          // };

          return (
            <section key={idx}>
              {message.sender === "user" && isNextMessageNpc
                ? message.content && (
                  <div
                    style={{
                      display: "flex",
                      marginLeft: "-12px",
                    }}
                  >
                    <span
                      style={{
                        color: "rgba(243,167,158,255)",
                        paddingLeft: "20px",
                        marginRight: "-10px",
                        fontWeight: "bold",
                      }}
                    >
                      {userName}:&nbsp;
                    </span>
                    <div className="messageStyle">{React.createElement('div', { dangerouslySetInnerHTML: { __html: DOMPurify.sanitize(processHTMLLinks(message.content)) } })}</div>
                  </div>
                )
                : message.sender === "npc" && isNextMessageUser
                  ? message.content && (
                    <div
                      style={{
                        display: "flex",
                        // justifyContent: "space-between",
                        marginLeft: "20px",
                      }}
                    >
                      <div>
                        <span
                          style={{
                            color: "rgba(127,210,118,255)",
                            marginRight: "-10px",
                            fontWeight: "bold",
                          }}
                        >
                          {npcName}:&nbsp;
                        </span>
                        <div className="messageStyle">
                          {React.createElement('div', { dangerouslySetInnerHTML: { __html: DOMPurify.sanitize(processHTMLLinks(message.content)) } })}
                        </div>
                      </div>
                    </div>
                  )
                  : ""}
            </section>
          );
        }

        )}
        {userText && (
          <div style={{ display: "flex", marginBottom: "-12px" }}>
            <p style={{
              color: "rgba(243,167,158,255)",
              paddingLeft: "20px",
              marginRight: "-10px",
              fontWeight: "bold",
            }}>
              {userName}:
            </p>
            <p style={{
              color: "#FFFFFF",
              paddingLeft: "15px",
              marginRight: "20px",
            }}>
              {userText}
            </p>
          </div>
        )}

        {/* Render active npcText */}
        {npcText && (
          <div style={{
            display: "flex",
            justifyContent: "space-between",
            marginLeft: "20px",
          }}>
            <div>
              <span style={{
                color: "rgba(127,210,118,255)",
                marginRight: "-10px",
                fontWeight: "bold",
              }}>
                {npcName}:&nbsp;
              </span>
              <div className="messageStyle">
                {React.createElement('div', { dangerouslySetInnerHTML: { __html: DOMPurify.sanitize(processHTMLLinks(npcText)) } })}
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        className="ConverSIM-container-textBox"
        
      >
        <div
          className="ConverSIM-textBox"
          style={{
            color: "white",
            fontSize: "13px",
          }}
        >
          {keyPressed && (
            <div className="ConverSIM-icon" style={{ marginBottom: "20px" }}>
              <span className="ConverSIM-span-prop" />
              <span className="ConverSIM-span-prop" />
              <span className="ConverSIM-span-prop" />
            </div>
          )}
          {!keyPressed && (
            <form onSubmit={handleSubmit}>
              <input
                className="ConverSIM-placeholder1"
                style={{
                  color: "white",
                }}
                onChange={handleChange}
                value={value}
                placeholder="Enter Question Here."
                type="text"
              />
              
            </form>
            
          )}
         

            <img id="ConverSIM-MicIcon" className='ConverSIM-MicIcon' 
                src={baseURL+"MicIcon-Red.png"} 
                alt='Microphone Icon' 
                onMouseDown={handleMicPress} 
                onMouseUp={handleMicRelease} 
                
                />
          
          {/* <img className='ConverSIM-MicIcon' src={baseURL+"MicIcon-Red.png"} alt='Microphone Icon' ontouchstart={handleMicPress} on onMouseUp={touchEnd} ontouchend={touchEnd} /> */}
          {/* Speaker icons are toggled for muted or unmuted states. */}
          <img className='ConverSIM-Speaker' src={baseURL+"SpeakerActive-Red.png"} alt='Audio Active' onMouseDown={muteAudio} style={{ display: audioMuted ? 'none' : 'block'}} />
          <img className='ConverSIM-Speaker' src={baseURL+"SpeakerMute-Red.png"} alt='Audio Muted' onMouseDown={unMuteAudio} style={{ display: audioMuted ? 'block' : 'none'}} />
        </div>
        
      
      </div>
      
      
    </section>

  );
};



